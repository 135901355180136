<template>
   <b-card
	   v-if="tableData"
	   class="card-company-table"
	   no-body
   >
	  <b-table
		  :fields="fields"
		  :items="$store.getters.projectActive.data"
		  class="mb-0 white-space"
		  responsive

		  show-empty
		  :sort-by="getOrderName(this.order)"
		  :sort-desc="this.sort==='desc'"
		  style=' max-height: 350px;'
		  @sort-changed="sortingChanged"
	  >
		 <template #empty="scope">
			<div class="text-center py-10 d-flex flex-column justify-items-center align-items-center">
			   <feather-icon
				   class="text-primary op2"
				   icon="MehIcon"
				   size="70"
			   />
			   <h3 class="my-1 op2">{{ $t('contacts.notfound-projects') }}</h3>
			</div>
		 </template>
		 <!-- company -->
		 <template #cell(title)="data">
			<div class="d-flex align-items-center">
			   <div>
				  <b-link :to="{ name: 'projects-view', params: { projectId: data.item.id } }"
						  class="mr-1 font-weight-bold d-block text-nowrap">
					 {{ truncate(data.item.title) }}
				  </b-link>
				  <small class="text-muted">{{ data.item.contact.surname }}
											{{ data.item.contact.name }}
				  </small>
			   </div>
			</div>
		 </template>

		 <!-- category -->
		 <template #cell(tasks)="data">
			<div class="d-flex align-items-center">
			   <feather-icon
				   :id="`invoice-row-${data.item.id}`"
				   class="mr-50 text-primary"
				   icon="LayersIcon"
				   size="18"
			   />
			   <span>{{ data.item.tasks.length }}</span>
			</div>
			<b-tooltip
				:target="`invoice-row-${data.item.id}`"
				placement="top"
			>
			   <p v-if="data.item.tasks"
				  class="mb-0">
				  Ολοκληρωμένα: {{ data.item.tasks.filter(el => el.status === 'Ολοκληρωμένο').length }}
			   </p>
			   <p v-if="data.item.tasks"
				  class="mb-0">
				  Σε εξέλιξη: {{ data.item.tasks.filter(el => el.status === 'Σε εξέλιξη').length }}
			   </p>
			</b-tooltip>
		 </template>

		 <!-- views -->
		 <template #cell(deadline)="data">
			<div class="d-flex flex-column">
			   <span class=" mb-25">{{ convertDate(data.item.deadline) }}</span>
			</div>
		 </template>

		 <template #cell(pending)="data">
			<template
				v-if="data.item.remainder === 0 && data.item.payments.length"
			>
			   Εξοφλημένο
			</template>
			<template
				v-else-if="data.item.remainder < 0 && data.item.payments.length"
			>
			   {{ data.item.remainder * -1 > 0 ? "€ +" : "€" }}
			   {{
				  new Intl.NumberFormat("de-DE", {
					 style: "currency",
					 currency: "EUR",
					 currencyDisplay: "code",
				  })
					  .format(Math.abs(data.item.remainder))
					  .replace("EUR", "")
					  .trim()
			   }}
			</template>
			<template
				v-else-if="
              data.item.remainder === 0 && !data.item.payments.length
            "
			>
			   {{
				  "€" +
				  new Intl.NumberFormat("de-DE", {
					 style: "currency",
					 currency: "EUR",
					 currencyDisplay: "code",
				  })
					  .format(Math.abs(data.item.remainder))
					  .replace("EUR", "")
					  .trim()
			   }}
			</template>
			<template v-else>

			   {{ data.item.remainder * -1 > 0 ? "€ +" : "€" }}
			   {{
				  new Intl.NumberFormat("de-DE", {
					 style: "currency",
					 currency: "EUR",
					 currencyDisplay: "code",
				  })
					  .format(Math.abs(data.item.remainder))
					  .replace("EUR", "")
					  .trim()
			   }}
			</template>
		 </template>

		 <!-- sales -->
		 <template #cell(completed)="data">
			<div class="d-flex align-items-center">
			   <span class=" mr-1">{{ data.item.task_completed_percentage }}%</span>
			</div>
		 </template>
	  </b-table>
   </b-card>
</template>

<script>
import {BAvatar, BCard, BImg, BLink, BTable, BTooltip,} from 'bootstrap-vue'
import caldoSettings from "@/caldoSettings";
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import moment from "moment";

export default {
   components: {
	  BCard,
	  BTooltip,
	  BTable,
	  BLink,
	  BAvatar,
	  BImg,
   },
   mixins: [CurrencyMixin],
   props: {
	  tableData: {
		 type: Array,
		 default: () => [],
	  },
   },
   methods: {
	  truncate(name, surname = '') {
		 return caldoSettings.truncateString(`${name} ${surname}`, 40)
	  },


	  getOrderName(value) {

		 switch (value) {
			case 'title':
			   return 'project'
			case 'category':
			   return 'projectCategory'
			case 'tasks':
			   return 'tasks'
			case 'status':
			   return 'status'
			case 'deadline':
			   return 'deadline'
			case 'remaining':
			   return 'pending'
			case 'id':
			   return 'id'
		 }

	  },

	  convertDate(date) {
		 if (date)
			return moment(date).format('DD-MM-YYYY')
		 return '-'
	  },
	  async getOrder() {
		 await this.$store.dispatch('fetchDynamicPreferences')
		 const order = await this.$store.getters.getDynamicPreference['dashboard_list_order'].split("_")


		 this.order = order[0]
		 this.sort = order[1];
	  },
	  async sortingChanged(value) {

		 console.log(value)
		 let order = {
			project: "title",
			projectCategory: "category",
			tasks: "tasks",
			status: "status",
			deadline: "deadline",
			pending: "remaining",
			id: "id",
		 };
		 this.order = order[value.sortBy];
		 this.sort = value.sortDesc ? 'desc' : 'asc';
		 await this.$store.dispatch("fetchProjectCategoriesActive", {
			params: {
			   "rpp": 999,
			   "orderBy": [this.order],
			   'status':'Σε εξέλιξη',
			   "sortBy": this.sort
			}
		 });

		 // self.totalPages = self.$store.getters.getExpensesess.total

		 let sortBy
		 let orderBy;

		 console.log(value);

		 switch (value.sortBy) {
			case 'project':
			   sortBy = 'title'
			   break;
			case 'projectCategory':
			   sortBy = 'category'
			   break;
			case 'tasks':
			   sortBy = 'tasks'
			   break;
			case 'status':
			   sortBy = 'status'
			   break;
			case 'deadline':
			   sortBy = 'deadline'
			   break;
			case 'pending':
			   sortBy = 'remaining'
			   break;
			case 'id':
			   sortBy = 'id'
			   break;
		 }

		 orderBy = value.sortDesc ? 'desc' : 'asc'


		 await this.$store.dispatch("setDynamicPreferences", ['dashboard_list_order', `${sortBy}_${orderBy}`]);



	  },


   },
 async  mounted() {
	await this.getOrder()
	 await this.$store.dispatch('fetchProjectCategoriesActive',{
		params: {
		   "rpp": 999,
		   "orderBy": [this.order],
		   'status':'Σε εξέλιξη',
		   "sortBy": this.sort
		}
	 }
	 );
	  this.$store.getters.projectActive.data = await this.$store.getters.projectActive.data;
   },

   data() {
	  return {

		 order: "",
		 sort: "",
		 data:null,
		 fields: [
			{key: 'title', label: this.$i18n.t('projects.project'), sortable: true},
			{key: 'tasks', label: 'tasks', sortable: true},
			{key: 'deadline', label: this.$i18n.t('projects.end-date'), sortable: true},
			{key: 'pending', label: this.$i18n.t('projects.pending'), sortable: false},
			{key: 'completed', label: this.$i18n.t('projects.completition-2'), sortable: false},
		 ],
	  }
   },
}
</script>

<style lang="scss"
	   scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';


.card-company-table ::v-deep td .b-avatar.badge-light-company {
   .dark-layout & {
	  background: $theme-dark-body-bg !important;
   }
}


.white-space {
   white-space: nowrap
}


.py-10 {
   padding: 50px 10px;
}


.op2 {
   opacity: 0.5;
}
</style>
