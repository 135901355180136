<template>
   <b-card
	   v-if="data"
	   class="card-revenue-budget"
	   no-body
   >
	  <b-row class="mx-0 align-items-center">
		 <b-col
			 class="revenue-report-wrapper"
			 md="8"
		 >
			<div class="d-sm-flex justify-content-between align-items-center mb-3">
			   <h4 class="card-title mb-50 mb-sm-0">
				  Ετήσια αναφορά
			   </h4>
			   <div class="d-flex align-items-center">
				  <div class="d-flex align-items-center mr-2">
					 <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
					 <span>Έξοδα</span>
				  </div>
				  <div class="d-flex align-items-center ml-75">
					 <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
					 <span>Έσοδα</span>
				  </div>
			   </div>
			</div>

			<!-- chart -->
			<vue-apex-charts
				id="revenue-report-chart"
				:options="revenueReport.chartOptions"
				:series="data"
				height="230"
				type="bar"
			/>
		 </b-col>

		 <b-col
			 class="budget-wrapper d-flex align-items-center flex-column"
			 md="4"
		 >
			<v-select
				v-model="year"
				:clearable="false"

				:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
				:options="yearsReport"
				:searchable="false"
				input-id="add-guests"
				label="etos"
				style="width: 100px"
				@input="onChangeYear"
			>
			</v-select>


			<h1 class="mb-25 font-3">
			   <!--			   €{{ year['payments']  }}-->
			</h1>
			<div class="d-flex justify-content-center display-4">
			   <span>€{{ showPrice(convertFormatPrice(services)) }}</span>
			</div>
			<div class="d-flex justify-content-center">
			   <span class="font-weight-bolder mr-25">Έξοδα:</span>
			   <span>€{{ showPrice(convertFormatPrice(payments)) }}</span>
			</div>

		 </b-col>
	  </b-row>
   </b-card>
</template>

<script>
import {BButton, BCard, BCol, BDropdown, BDropdownItem, BRow,} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from "moment";
import _ from "lodash";
import caldoSettings from "@/caldoSettings";
import CurrencyMixin from "@/Mixins/CurrencyMixin";

export default {
   components: {
	  VueApexCharts,
	  BDropdown,
	  BDropdownItem,
	  BCard,
	  BButton,
	  vSelect,
	  BRow,
	  BCol,
   },
   directives: {
	  Ripple,
   },
   mixins: [CurrencyMixin],
   props: {
	  data: {
		 type: Array,
		 default: () => {
		 },
	  },
	  yearsReport: {
		 type: Array,
	  },
   },
   data() {
	  return {
		 year: moment().format('y'),
		 services: "",
		 payments: "",
		 revenue_report: {},
		 revenueReport: {
			chartOptions: {
			   chart: {
				  stacked: true,
				  type: 'bar',
				  toolbar: {show: false},
			   },
			   grid: {
				  padding: {
					 top: -20,
					 bottom: -10,
				  },
				  yaxis: {
					 lines: {show: true},
				  },
			   },
			   xaxis: {
				  categories: caldoSettings.monthsShort,
				  labels: {
					 style: {
						colors: '#6E6B7B',
						fontSize: '0.86rem',
						fontFamily: 'Montserrat',
					 },
				  },
				  axisTicks: {
					 show: false,
				  },
				  axisBorder: {
					 show: false,
				  },
			   },
			   legend: {
				  show: false,
			   },
			   dataLabels: {
				  enabled: false,
			   },
			   colors: [$themeColors.primary, $themeColors.warning],
			   plotOptions: {
				  bar: {
					 columnWidth: '17%',
					 endingShape: 'rounded',
				  },
				  distributed: true,
			   },
			   yaxis: {
				  labels: {
					 style: {
						colors: '#6E6B7B',
						fontSize: '0.86rem',
						fontFamily: 'Montserrat',
					 },
				  },
			   },
			},
		 }
	  }
   },
   mounted() {


	  setTimeout(() => {


		 console.log(this.yearsReport)
		 this.services = _.find(this.yearsReport, {'etos': moment().format('y')})?.service;
		 this.payments = _.find(this.yearsReport, {'etos': moment().format('y')})?.payments;
	  }, 1500)


	  // this.services = this.services == '' ? 0 : this.services;
	  // this.payments = this.payments == '' ? 0 : this.payments;
	  // console.log(this.services)
	  // console.log(this.payments)
   },
   methods: {
	  showPrice(value) {

		 if (value == 'NaN') {
			return 0
		 } else {
			return value
		 }

	  },
	  onChangeYear(e) {

		 this.$emit('getYear',e.etos)
		 this.payments = e.payments;
		 this.services = e.service;

	  }
   }
}
</script>

<style scoped>
h1.font-3 {
   font-size: 3rem;
}
</style>