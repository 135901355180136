<template>
   <div>
	  <section id="dashboard-analytics">
		 <b-row class="match-height">
			<b-col lg="6"
				   md="12">
			   <dashboard-congratulation
				   :tasks="$store.getters.getDashboard.tasks"
				   :todos="$store.getters.getDashboard.todos"
			   />

			</b-col>
			<b-col lg="3"
				   sm="6">
			   <statistic-card-with-area-chart
				   id="delete-this-after-used-1"
				   :chart-data="payments"
				   :statistic="$store.getters.getDashboard.paymentsSum"
				   icon="SmileIcon"
				   statistic-title="Έσοδα μήνα"
			   />
			</b-col>
			<b-col lg="3"
				   sm="6">
			   <statistic-card-with-area-chart
				   :chart-data="[]"
				   :statistic="abs($store.getters.getDashboard.all_years_ofiles * -1)"
				   :statistic-title="text"
				   color="danger"
				   icon="FrownIcon"
			   />
			</b-col>
		 </b-row>

		 <b-row class="match-height">
			<!-- Projects Table Card -->
			<b-col lg="8">
			   <ecommerce-company-table :table-data="data.companyTable" />
			</b-col>
			<!--/ Projects Table Card -->

			<b-col lg="4"
				   md="6">
			   <ecommerce-transactions
				   :data="dataEc.transactionData"
				   :task-list="taskList"
			   />
			</b-col>
		 </b-row>

		 <b-row>
			<b-col>
			   <dashboard-revenue-report
				   @getYear="getYear"
				   :data="paymentChart"
				   :yearsReport="yearsReport"
			   />
			</b-col>
		 </b-row>
	  </section>


	  <b-card-code id="tour-card"
				   class="d-none"
				   title="Tour">
		 <b-button
			 v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			 variant="outline-primary"
			 @click="tourStart"
		 >
			Start Tour
		 </b-button>

		 <app-tour :steps="steps" />

		 <template #code>
			{{ codeTour }}
		 </template>
	  </b-card-code>


   </div>
</template>


<script>
import {BCol, BRow} from "bootstrap-vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import {kFormatter} from "@core/utils/filter";
import DashboardCongratulation from "./DashboardCongratulation.vue";
import DashboardRevenueReport from "./DashboardRevenueReport.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";

import EcommerceBrowserStates from "@/views/dashboard/ecommerce/EcommerceBrowserStates";
import EcommerceTransactions from "@/views/dashboard/ecommerce/EcommerceTransactions";
import {getUserData} from "@/auth/utils";
import _ from "lodash";
import caldoSettings from "@/caldoSettings";

// tour files
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {BButton} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppTour from "@core/components/app-tour/AppTour.vue";
import {codeTour} from "./code";
import moment from "moment";

export default {
   name: "ShepherdExample",
   components: {
	  BRow,
	  BCol,
	  BCardCode,
	  BButton,
	  AppTour,
	  DashboardCongratulation,
	  StatisticCardWithAreaChart,
	  DashboardRevenueReport,
	  EcommerceCompanyTable,
	  StatisticCardVertical,
	  EcommerceBrowserStates,
	  EcommerceTransactions,
   },
   data() {
	  return {
		 text: "",
		 data: {},
		 dataEc: {},
		 payments: [],
		 paymentChart: [],
		 yearsReport: [],
		 taskList: [],
		 //   tour files
		 codeTour,
		 steps: [
			{
			   target: "#test",
			   header: {
				  title: "Card",
			   },
			   content: "This card contains demo for the tour.",
			},
			{
			   target: "#tour-card .icon-code",
			   header: {
				  title: "View demo Source",
			   },
			   content:
				   "If you ever find your self confused, you can click on this code icon to check the source of current demo.",
			},
			{
			   target: "#tour-card .btn",
			   header: {
				  title: "Trigger Tour",
			   },
			   content: "You can click on this button to trigger the tour.",
			},
		 ],
	  };
   },
   directives: {
	  Ripple,
   },
   mounted: function () {


		 setTimeout(()=>{

			this.$tours['myTour'].start()
		 },800)



	  // setTimeout(()=>{
	  //
	  // },400)

   },
   async created() {
	  await this.$store.dispatch("fetchDashboard");

	  _.keys(this.$store.getters.getDashboard.year_reports).forEach((el) => {
		 this.yearsReport.push({
			etos: el,
			service:
			this.$store.getters.getDashboard.year_reports[el].totalPayments,
			payments:
			this.$store.getters.getDashboard.year_reports[el].totalExpenses,
		 });
	  });
	  await this.$store.dispatch("fetchTodoMain");
	  // await this.$store.dispatch("fetchProjectCategoriesActive");

	  this.payments = [
		 {name: "", data: _.values(this.$store.getters.getDashboard.payments)},
	  ];

	  let paymentChart = _.values(
		  this.$store.getters.getDashboard.year_reports[moment().year()]?.stats
	  ).map((el) => el.expenses);
	  let servicesChart = _.values(
		  this.$store.getters.getDashboard.year_reports[moment().year()]?.stats
	  ).map((el) => el.payments);

	  this.taskList = this.$store.getters.getDashboard.taskList;

	  this.paymentChart = [
		 {
			name: "Έξοδα",
			data: paymentChart,
		 },
		 {
			name: "Έσοδα",
			data: servicesChart,
		 },
	  ];

	  if (this.$store.getters.getDashboard.all_years_ofiles * -1 > 0) {
		 this.text = "Πλεόνασμα";
	  } else {
		 this.text = " Υπόλοιπα πελατών";
	  }
	  // data
	  //   this.$http.get("/analytics/data").then((response) => {
	  // 	 this.data = response.data;
	  //   });
	  //
	  //
	  // this.$http.get("/ecommerce/data").then((response) => {
	  //  this.dataEc = response.data;
	  //
	  //  const userData = getUserData();
	  //  this.dataEc.congratulations.name =
	  // 	 userData.fullName?.split(" ")[0] || userData.username;
	  // });
   },
   methods: {
	  kFormatter,

	  abs(value) {
		 return caldoSettings.abs(value);
	  },
	  getYear(value) {
		 let paymentChart = _.values(
			 this.$store.getters.getDashboard.year_reports[value]?.stats
		 ).map((el) => el.expenses);
		 let servicesChart = _.values(
			 this.$store.getters.getDashboard.year_reports[value]?.stats
		 ).map((el) => el.payments);


		 this.paymentChart = [
			{
			   name: "Έξοδα",
			   data: paymentChart,
			},
			{
			   name: "Έσοδα",
			   data: servicesChart,
			},
		 ];

	  },
	  // tour steps
	  tourStart() {
		 this.$tours.vuexyTour.start();
	  },
   },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// tour files
@import "@core/scss/vue/libs/tour.scss";
</style>


