<template>
   <b-card
	   v-if="data"
	   class="card-transaction"
	   no-body
	   style="height: 350px"
   >


	  <b-tabs content-class="">
		 <b-tab active
				title="Todo">
			<div
				v-if="isExists($store.getters.getTodos)"
				style=' max-height: 300px; overflow: scroll'
			>
			   <b-card-body

				   v-for="(todo,idx) in $store.getters.getTodos"
				   :key="todo.id"
				   class="todo-item d-flex align-items-start mb-1 justify-content-between py-0"
			   >
				  <b-media no-body>
					 <b-media-aside>
						<b-avatar
							:variant="isHigh(todo)"
							rounded
							size="42"
						>
						   <feather-icon
							   icon="CreditCardIcon"
							   size="18"
						   />
						</b-avatar>
					 </b-media-aside>
					 <b-media-body>
						<h6 class="todo-title m-0">
						   {{ truncateString(todo.title, 80) }}
						</h6>
						<small>{{ convertDate(todo.dueDate) }}</small>
					 </b-media-body>
				  </b-media>
				  <div class="d-flex">

					 <b-badge
						 v-for="tag in todo.tags"
						 :key="tag.id"
						 :style="{backgroundColor: hexToRgbA(tag.color)}"
						 class="text-capitalize"
						 pill
					 >
					  <span :style="{color: tag.color}"
							class="text-truncate">
										  {{ tag.name }}
										  </span>
					 </b-badge>
				  </div>
			   </b-card-body>
			</div>
			<div v-else>
			   <b-card-body
			   >
				  <div class="text-center py-10 d-flex flex-column justify-items-center align-items-center">
					 <feather-icon
						 class="text-primary op2"
						 icon="CheckSquareIcon"
						 size="40"
					 />
					 <h3 class="my-1 op2">{{ $t('generic.notfound-generic') }}</h3>
				  </div>
			   </b-card-body>
			</div>
		 </b-tab>
		 <b-tab title="Εργασίες">
			<div v-if="isExists($store.getters.getDashboard.taskList)"
				 style=' max-height: 300px; overflow: scroll'>
			   <b-card-body
				   v-for="(task,idx) in $store.getters.getDashboard.taskList"

				   :key="task.id"
				   class="todo-item d-flex align-items-start mb-1 justify-content-between py-0"
			   >
				  <b-media no-body>
					 <b-media-aside>
						<b-avatar
							:variant="getColorStatus(task.status)"
							rounded
							size="42"
						>
						   <feather-icon
							   icon="LayersIcon"
							   size="18"
						   />
						</b-avatar>
					 </b-media-aside>
					 <b-media-body>
						<h6 class="todo-title m-0">
						   {{ truncateString(task.title, 80) }}
						</h6>
						<small>{{ task.project.title }} / {{ convertDate(task.project.endDate) }}</small>
					 </b-media-body>
				  </b-media>
				  <div class="d-flex">

					 <b-badge
						 :class="'bg-'+getColorStatus(task.status)"
						 class="text-capitalize"
						 pill
					 >
					  <span class="text-truncate">
										  {{ task.status }}
										  </span>
					 </b-badge>
				  </div>
			   </b-card-body>
			</div>
			<div v-else>
			   <b-card-body
			   >
				  <div class="text-center py-10 d-flex flex-column justify-items-center align-items-center">
					 <feather-icon
						 class="text-primary op2"
						 icon="LayersIcon"
						 size="40"
					 />
					 <h3 class="my-1 op2">{{ $t('generic.notfound-generic') }}</h3>
				  </div>
			   </b-card-body>
			</div>
		 </b-tab>

	  </b-tabs>


	  <!--	  <ul class="nav nav-pills mb-3"-->
	  <!--		  id="pills-tab"-->
	  <!--		  role="tablist">-->
	  <!--		 <li class="nav-item"-->
	  <!--			 role="presentation">-->
	  <!--			<b-card-header>-->
	  <!--			   <button class="nav-link active"-->
	  <!--					   id="pills-home-tab"-->
	  <!--					   data-bs-toggle="pill"-->
	  <!--					   data-bs-target="#pills-home"-->
	  <!--					   type="button"-->
	  <!--					   role="tab"-->
	  <!--					   aria-controls="pills-home"-->
	  <!--					   aria-selected="true">Todos-->
	  <!--			   </button>-->
	  <!--			</b-card-header>-->
	  <!--		 </li>-->

	  <!--		 <li class="nav-item"-->
	  <!--			 role="presentation">-->
	  <!--			<b-card-header>-->
	  <!--			<button class="nav-link"-->
	  <!--					id="pills-profile-tab"-->
	  <!--					data-bs-toggle="pill"-->
	  <!--					data-bs-target="#pills-profile"-->
	  <!--					type="button"-->
	  <!--					role="tab"-->
	  <!--					aria-controls="pills-profile"-->
	  <!--					aria-selected="false">Profile-->
	  <!--			</button>-->
	  <!--			</b-card-header>-->
	  <!--		 </li>-->
	  <!--	  </ul>-->


	  <!--	  <b-card-body class="tab-content" id="pills-profile">-->
	  <!--	asdsads-->
	  <!--	  </b-card-body>-->


   </b-card>
</template>

<script>
import caldoSettings from "@/caldoSettings";
import {
   BAvatar,
   BBadge,
   BCard,
   BCardBody,
   BCardHeader,
   BCardTitle,
   BDropdown,
   BDropdownItem, BListGroup, BListGroupItem,
   BMedia,
   BMediaAside,
   BMediaBody,
   BTab,
   BTabs,
} from 'bootstrap-vue'
import moment from "moment";

export default {
   components: {
	  BCard,
	  BCardHeader,
	  BCardTitle,
	  BCardBody,
	  BTabs,
	  BTab,
	  BMediaBody,
	  BBadge,
	  BMedia,
	  BListGroup,
	  BListGroupItem,
	  BMediaAside,
	  BAvatar,
	  BDropdown,
	  BDropdownItem,
   },


   methods: {
	  convertDate(date) {
		 return moment(date).format('DD-MM-YYYY HH:mm')
	  },
	  hexToRgbA(hex) {
		 var c;
		 if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('');
			if (c.length == 3) {
			   c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x' + c.join('');
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.12)';
		 }
		 throw new Error('Bad Hex');
	  },
	  truncateString(str, num) {
		 if (str.length > num) {
			return str.slice(0, num) + "...";
		 } else {
			return str;
		 }
	  },
	  getColorStatus(color) {
		 const colors = {
			'Ολοκληρωμένο': "light-success",
			'Σε αναμονή': "light-warning",
			'Ακυρωμένο': "light-danger",
			'Σε εξέλιξη': "light-primary",
		 }
		 return colors[color];
	  },
	  isExists(value) {
		 if (value?.length) {
			return true;
		 }
		 return false;
	  },
	  isHigh(todo) {


		 return caldoSettings.getColor(
			 todo.tags.length >= 2 ? '#ff9f43' : '#7367f0'
		 )
	  }
   },
   props: {
	  data: {
		 type: Array,
		 default: () => [],
	  },
   },
}
</script>

<style lang="scss"
	   scoped>
.py-10 {
   padding: 50px 10px;
}


.op2 {
   opacity: 0.6;
}
</style>
