<template>
   <b-card
	   class="card card-congratulations"
	   text-variant="center"
   >
	  <!-- images -->
	  <b-img
		  :src="require('@/assets/images/elements/decore-left.png')"
		  class="congratulations-img-left"
	  />
	  <b-img
		  :src="require('@/assets/images/elements/decore-right.png')"
		  class="congratulations-img-right"
	  />
	  <!--/ images -->

	  <b-avatar
		  class="shadow mb-2"
		  size="70"
		  variant="primary"
	  >
		 <feather-icon
			 icon="AwardIcon"
			 size="28"
		 />
	  </b-avatar>
	  <h1 class="mb-1 mt-50 text-white">
		 {{ $t("dashboard.welcome") }}
	  </h1>
	  <b-card-text class="m-auto w-100">
		 {{ $t("dashboard.have") }} <strong>{{ todos }} todos</strong> {{ $t("dashboard.and") }} <strong>{{ tasks }} {{
			$t("dashboard.tasks")
																										 }}</strong>
		 {{ $t("dashboard.see-more") }}
	  </b-card-text>
   </b-card>
</template>

<script>
import {BAvatar, BCard, BCardText, BImg,} from 'bootstrap-vue'

export default {
   components: {
	  BCard,
	  BAvatar,
	  BImg,
	  BCardText,
   },
   props: {
	  tasks: Number,
	  todos: Number,
   },
}
</script>
